import React, { useRef, useState } from 'react';
import loaderGif from '../Assets/images/loading.gif'
import '../Components/RegModal/RegModal.css'

const Loader = () => {
    return (
        <div className="loader"><img src={loaderGif} alt="loader GIF" /></div>
    )
}


const PopupModal = (props) => {
    const [isChecked, setIsChecked] = useState(true);
    const buttonRef = useRef(null)

    return (
        <>
            <div className="modal fade regModalToggle mainPopupHome" id="regModalToggle" aria-hidden="true" aria-labelledby="regModalToggleLabel" tabIndex="1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="regModalToggleLabel">OxCash Grand Awards</h5>
                            <button type="button" className="btn-close" onClick={props.onClose} ref={buttonRef} data-coreui-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className='inrBodySroll'>
                                <img src='/images/ox_cash.jpg?v=200202' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PopupModal