import React, { useCallback, useEffect, useState } from 'react'
import './Dashboard.css'
import logofav from '../../Assets/images/logofav.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';
import { ShoppingCart } from '@material-ui/icons';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useContract, useStakeContract, useTokenContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import getLast10TransactionsAndEvents from '../../services/activities';
import { useLocation, useNavigate } from 'react-router';
import { OxNFTContract, atxContract, levelPrices, swapAddress, usdtContract, BASE_BSC_SCAN_URLS, names, BASE_URL, profileURL, profileImage } from '../../config';
import Slot from '../../Components/Slot/Slot';
import Footer from '../../Components/Footer/Footer';
import copyIcon from '../../Assets/images/copy.png'
import { Link } from '@material-ui/core';
import { calculateTotalInvested } from '../../utils';
import fetchUserIncome from '../../services/userIncome';
import CIcon from '@coreui/icons-react'
import { cilContact } from '@coreui/icons'
import { cilMeh } from '@coreui/icons'
import { cilBaby } from '@coreui/icons'
import { cilSnowflake } from '@coreui/icons'
import tether from '../../Assets/images/tether.png'
import ratioIcon from '../../Assets/images/icons/ratio-icon.gif'
import incomeIcon from '../../Assets/images/icons/income-icon.gif'
import partnersIcon from '../../Assets/images/icons/partners-icon.gif'
import unlockedIcon from '../../Assets/images/icons/unlocked-icon.gif'
import nft15 from '../../Assets/images/nfts/nft15.gif'
import { useOxProfile } from '../../hooks/useOxProfile';
import { useIsSigned, useSignMessage } from '../../hooks/useIsSigned';
import { useStake } from '../../state/account/hooks';
import { useOcPrice } from '../../hooks/useOcPrice';
const copySuccessfully = () => toast.success('Copied Successfully!');
function Dashboard() {

    const { library, chainId, account } = useActiveWeb3React()
    const [isInTrn, setTransaction] = useState(false);
    const [totalMembers, setTotalMembers] = useState(0);
    const [userId, setUserId] = useState(0);
    const [yesterdayMembers, setYesterdayMembers] = useState(0);
    const [yesterdayIncome, setYesterdayIncome] = useState(0);
    const [totalInvested, setTotalInvested] = useState(0);
    const [allInvested, setAllInvested] = useState(0);
    const [profitRatio, setProfitRatio] = useState(0);
    const [activity, setActivity] = useState([]);

    const { approveUSDT: approveOC } = useStake()

    const [userAddress, setUserAddress] = useState('');
    const [userTotalIncome, setUserTotalIncome] = useState(0);
    const [levelBought, setLevelBought] = useState(0);
    const [partners, setPartners] = useState(0);
    const [directIncome, setDirectIncome] = useState(0);
    const [levelIncome, setLevelIncome] = useState(0);
    const [myTeamTotal, setMyTeamTotal] = useState(0);
    const [oxCashBonanza, setoxCashBonanza] = useState(0);
    const treeNodes = 31;

    const totalLevels = 15;
    let tempidsArray = new Array(totalLevels).fill(0).map(() => new Array(treeNodes).fill(0));
    const [idsArray, setIdsArray] = useState(tempidsArray);

    const [reinvestCounts, setReinvestCounts] = useState(Array(15).fill(0));

    const planC = useStakeContract();
    const { data } = useOxProfile({ userID: userId })
    const location = useLocation();

    const { isSigned } = useIsSigned({ userAddress: userAddress })

    const usdtInstance = useTokenContract(usdtContract);
    const ocPrice = useOcPrice()


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
    }, [setUserId, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetchPanelDetails();
            fetchIncome();
        }
    }, [userId, planC])

    const fetchIncome = useCallback(async () => {
        let d = await fetchUserIncome(userId);

        setDirectIncome(d.sumDirectPaidEvents)
        setLevelIncome(d.sumTreePayoutEvents)
    }, [userId]);


    const fetchPanelDetails = useCallback(async () => {
        console.log("user id", userId)
        let actDiv = document.getElementById('activitiesView');
        actDiv.classList.add('loaderData');


        let userAddress;
        userAddress = await planC.getAddressById(userId);

        const userInfoResponse = await planC.userInfos(userAddress);

        // console.log("u", userInfoResponse);
        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        const _totalInvested = calculateTotalInvested(userInfoResponse['levelBought'].toNumber())
        console.log("totalInvested", _totalInvested, userInfoResponse['levelBought'].toNumber())
        setTotalInvested(_totalInvested)

        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
        const partners = ethers.BigNumber.from(userInfoResponse["partners"]).toNumber();
        const userTotalIncome = ethers.BigNumber.from(ethers.BigNumber.from(userInfoResponse["totalIncome"]).div(ethers.BigNumber.from("1000000000000000000"))).toNumber();

        setUserAddress(userAddress)
        setUserTotalIncome(userTotalIncome)

        let tMs = await planC.lastIDCount();
        setTotalMembers(ethers.BigNumber.from(tMs).toNumber());
        let totalInvestedBN = await planC.totalValueInvested();
        let allTotalValue = ethers.utils.formatEther(totalInvestedBN)
        setAllInvested(allTotalValue);

        if (levelBought > 0) {
            if (levelBought > 15) {
                setLevelBought(15)
            } else {
                setLevelBought(levelBought)
            }
        }
        setPartners(partners)

        const tempreinvestCounts = await getReinvestCounts(userAddress);
        // console.log("temp->",tempreinvestCounts[0])
        setReinvestCounts(tempreinvestCounts)
        let userWithLevels = tempidsArray;

        const ids = await planC.getAllLevelTrees(userAddress);
        const uniqueIds = [];
        for (let i = 0; i < 15; i++) {
            if (ids.tree) {
                ids.tree[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    uniqueIds[id] = id;
                    if (userWithLevels[i]) userWithLevels[index] = id;
                });
            } else {
                ids[i].forEach((response, index) => {
                    const id = ethers.BigNumber.from(response).toNumber();
                    uniqueIds[id] = id;
                    if (userWithLevels[i]) userWithLevels[i][index] = id;
                });
            }
        }

        let countP = 0;
        uniqueIds.forEach((t) => {
            countP++;
        });
        setIdsArray(userWithLevels);
        let teamSizeCheck = tempreinvestCounts[0];

        if (parseInt(userId) === 1) {
            teamSizeCheck = teamSizeCheck - 1;
        }
        let addOnTeamMemberCycle = 0;
        if (teamSizeCheck > 0) {
            addOnTeamMemberCycle = teamSizeCheck * 30;
        }

        if (parseInt(userId) === 1) {
            addOnTeamMemberCycle = addOnTeamMemberCycle + 1;
        }

        setMyTeamTotal(countP - 2 + addOnTeamMemberCycle);

        fetch('https://oc-price-api.vercel.app/ox-bonanza/' + userId)
            .then(response => response.json())
            .then(data => {
                const oc = data.sum; // Assuming 'oc' is the key in the JSON response
                // console.log('OC:', oc);
                setoxCashBonanza(oc);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        let getActivity = await getLast10TransactionsAndEvents(library);
        setActivity(getActivity)

        actDiv.classList.remove('loaderData');
    }, [planC, userId, location, tempidsArray])

    useEffect(() => {
        if (parseFloat(totalInvested) !== 0 && parseFloat(userTotalIncome) !== 0) {
            console.log(userTotalIncome, totalInvested)
            setProfitRatio((userTotalIncome / totalInvested) * 100)
        }
    }, [totalInvested, userTotalIncome])

    const counts = []
    async function getReinvestCounts(_user) {
        for (let i = 1; i <= 15; i++) {
            let count = await planC.userReInvestNumbers(_user, i);
            counts[i - 1] = parseInt(count);
        }
        return counts;
    }
    const navigate = useNavigate();

    const handleSlotClick = async (_userid, _level) => {
        if (userId !== 0) {
            navigate('/dashboard-user?user_id=' + userId + '&slot=' + _level)
        }
    }

    async function delayService(sec = 8000) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(true), sec)
        })
    }

    const handleBuyLevel = async (level) => {
        if (!account) {
            alert("Please connect your wallet");
            return;
        }

        if (userAddress !== account) {
            alert("You can't Buy other person's Slot, if its yours then connect your wallet with this ID");
            return;
        }

        if (levelBought + 1 < level) {
            alert("You have to buy previous level first");
            throw new Error("You have to buy previous level first");
        }

        try {
            setTransaction(true);
            const allowance = await usdtInstance.allowance(userAddress, planC.address)
            let allowanceParsed = ethers.utils.formatUnits(allowance, 18)
            const ocAmount = await planC.getOCAmount(ethers.utils.parseEther(levelPrices[level - 1].toString()))
            let ocAmountParsed = ethers.utils.formatUnits(ocAmount, 18)

            console.debug(parseFloat(allowanceParsed), parseFloat(ocAmountParsed), level)

            if (parseFloat(allowanceParsed) < parseFloat(ocAmountParsed)) {
                toast.error("OC Allowance is low. Approve First")
                await approveOC()
            }

            const buyLevelPromise = await planC.buyLevel(level);
            await toast.promise(
                buyLevelPromise.wait(),
                {
                    pending: 'Level Upgrade in process',
                    success: 'Level Upgrade Completed! 👌',
                    error: 'Level Upgrade failed '
                }
            )

            await delayService();
            fetchPanelDetails()
            setTransaction(false);
        } catch (error) {
            setTransaction(false);
            toast.error(`Level Upgrade failed - ${error.reason ? error.reason : (error.data ? error.data.message : error.message)}`)
            console.log(error)
        }
    }

    const { signMessage } = useSignMessage()

    if (!isSigned) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3>Please sign the message to proceed</h3>
                        <button className="btn btn-primary" onClick={signMessage}>Sign</button>
                    </div>
                </div>
            </div>
        )
    }

    const isDesktop = window.innerWidth > 766;
    return (
        <>
            <section className='dashboardPreviewMain dashboardMian'>
                {/* <div className='titleBox'>
                        <h2>Welcome to Oxcash NFTPro 3.0</h2>
                    </div> */}
                <div className='row tablemainnet'>
                    <div className='col-12 col-md-8 order-1 order-md-0'>
                        <div className='row'>
                            <div className='col-12 col-sm-12 mb-4'>
                                <div className='inrBox'>
                                    <a href="https://bbevents.live" className='buyogaBtnDh' target='_blank'>Buy OGA Tickets</a>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Direct Partners</p>
                                    <h3>{partners}</h3>
                                    <img src={partnersIcon} alt="partners icon" />
                                    {/* <CIcon icon={cilContact} /> */}
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Slots Unlocked</p>
                                    <h3>{levelBought}</h3>
                                    <img src={unlockedIcon} alt="Unloack" />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Profit Ratio</p>
                                    <h3>{profitRatio.toFixed(2)}%</h3>
                                    <img src={ratioIcon} alt="Ratio icon" />
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 mb-4'>
                                <div className='inrBox'>
                                    <p>Profits</p>
                                    <h3>$ {userTotalIncome}</h3>
                                    <img src={incomeIcon} alt="Profits" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 order-0 order-md-0 progileDashBx'>
                        <div className='profilebox'>
                            <div className='imgboxprof'>
                                <img className="prfllgo" src={logofav} style={{ borderRadius: "50%" }} alt="ox profile" width="60" height="60" />
                                {/* <img className="prfllgo" src={data['image'] && data['image'] !== "undefined" ? `${data['image']}` : logofav} style={{borderRadius: "50%"}} alt="ox profile" width="60" height="60" /> */}
                            </div>
                            <h2>ID # {userId}</h2>
                            {/* { (account && userAddress.toLowerCase() === account.toLowerCase()) && <a href={profileURL} target="_blank" className='editProfileBtn'>Edit Profile</a> } */}
                            <ul className='profileLinks'>
                                {/* { data['mobile_number'] && <li>
                                    <a href={`tel:${data['mobile_number']}`} rel="noreferrer" target="_blank" title='Mobile'>
                                        <svg width="800px" height="800px" viewBox="-2 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M11 4.5H7" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.99995 18.1C9.85599 18.1 10.55 17.406 10.55 16.55C10.55 15.694 9.85599 15 8.99995 15C8.14391 15 7.44995 15.694 7.44995 16.55C7.44995 17.406 8.14391 18.1 8.99995 18.1Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </a>
                                </li> } */}
                                {/* { data['email'] && <li>
                                    <a href={`mailto:${data['email']}`} rel="noreferrer" target="_blank" title='Email'>
                                        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z" stroke="#000000" stroke-width="2" stroke-linecap="round" />
                                        </svg>
                                    </a>
                                </li> }
                                { data['whatsapp'] && <li>
                                    <a href={`https://wa.me/${data['whatsapp']}`} rel="noreferrer" target="_blank" title='WhatsApp'>
                                        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M11.42 9.49c-.19-.09-1.1-.54-1.27-.61s-.29-.09-.42.1-.48.6-.59.73-.21.14-.4 0a5.13 5.13 0 0 1-1.49-.92 5.25 5.25 0 0 1-1-1.29c-.11-.18 0-.28.08-.38s.18-.21.28-.32a1.39 1.39 0 0 0 .18-.31.38.38 0 0 0 0-.33c0-.09-.42-1-.58-1.37s-.3-.32-.41-.32h-.4a.72.72 0 0 0-.5.23 2.1 2.1 0 0 0-.65 1.55A3.59 3.59 0 0 0 5 8.2 8.32 8.32 0 0 0 8.19 11c.44.19.78.3 1.05.39a2.53 2.53 0 0 0 1.17.07 1.93 1.93 0 0 0 1.26-.88 1.67 1.67 0 0 0 .11-.88c-.05-.07-.17-.12-.36-.21z" /><path d="M13.29 2.68A7.36 7.36 0 0 0 8 .5a7.44 7.44 0 0 0-6.41 11.15l-1 3.85 3.94-1a7.4 7.4 0 0 0 3.55.9H8a7.44 7.44 0 0 0 5.29-12.72zM8 14.12a6.12 6.12 0 0 1-3.15-.87l-.22-.13-2.34.61.62-2.28-.14-.23a6.18 6.18 0 0 1 9.6-7.65 6.12 6.12 0 0 1 1.81 4.37A6.19 6.19 0 0 1 8 14.12z" /></svg>
                                    </a>
                                </li> } */}
                                {/* { data['telegram'] && */}
                                <li>
                                    <a href={`https://t.me/oxcashchat`} target="_blank" title='Telegram'>
                                        <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1">
                                            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Brand" transform="translate(-672.000000, -0.000000)">
                                                    <g id="telegram_line" transform="translate(672.000000, 0.000000)">
                                                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">
                                                        </path>
                                                        <path d="M21.8394,6.05639 C22.0315,4.8917 20.8652,3.97177 19.7773,4.42984 L2.67703,11.63 C1.48461,12.132 1.42351,13.8558 2.67788,14.3758 C3.60596,14.7605 5.02633,15.3246 6.45085,15.7943 C7.61932,16.1795 8.8931,16.5371 9.91353,16.6383 C10.1929,16.9725 10.5445,17.2935 10.9017,17.5872 C11.4487,18.0371 12.1074,18.5012 12.7873,18.9455 C14.1489,19.8352 15.6597,20.6865 16.678,21.2396 C17.8949,21.9006 19.3517,21.1395 19.5705,19.8131 L21.8394,6.05639 Z M4.59485,12.9925 L19.7186,6.62459 L17.6009,19.4649 C16.6024,18.9219 15.163,18.1087 13.8813,17.2713 C13.2329,16.8475 12.6407,16.4279 12.172,16.0425 C12.0051,15.9052 11.8638,15.7802 11.7461,15.6683 L15.7072,11.7071 C16.0977,11.3166 16.0977,10.6834 15.7072,10.2929 C15.3167,9.90237 14.6835,9.90237 14.293,10.2929 L9.95476,14.6311 C9.22132,14.5373 8.19888,14.2647 7.07709,13.8949 C6.21377,13.6102 5.34574,13.2869 4.59485,12.9925 Z" id="形状" fill="#09244B">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a href={`https://t.me/Oxchange_Finance`} target="_blank" title='Telegram'>
                                        <svg width="800px" height="800px" viewBox="0 0 24 24" version="1.1">
                                            <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Brand" transform="translate(-672.000000, -0.000000)">
                                                    <g id="telegram_line" transform="translate(672.000000, 0.000000)">
                                                        <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">
                                                        </path>
                                                        <path d="M21.8394,6.05639 C22.0315,4.8917 20.8652,3.97177 19.7773,4.42984 L2.67703,11.63 C1.48461,12.132 1.42351,13.8558 2.67788,14.3758 C3.60596,14.7605 5.02633,15.3246 6.45085,15.7943 C7.61932,16.1795 8.8931,16.5371 9.91353,16.6383 C10.1929,16.9725 10.5445,17.2935 10.9017,17.5872 C11.4487,18.0371 12.1074,18.5012 12.7873,18.9455 C14.1489,19.8352 15.6597,20.6865 16.678,21.2396 C17.8949,21.9006 19.3517,21.1395 19.5705,19.8131 L21.8394,6.05639 Z M4.59485,12.9925 L19.7186,6.62459 L17.6009,19.4649 C16.6024,18.9219 15.163,18.1087 13.8813,17.2713 C13.2329,16.8475 12.6407,16.4279 12.172,16.0425 C12.0051,15.9052 11.8638,15.7802 11.7461,15.6683 L15.7072,11.7071 C16.0977,11.3166 16.0977,10.6834 15.7072,10.2929 C15.3167,9.90237 14.6835,9.90237 14.293,10.2929 L9.95476,14.6311 C9.22132,14.5373 8.19888,14.2647 7.07709,13.8949 C6.21377,13.6102 5.34574,13.2869 4.59485,12.9925 Z" id="形状" fill="#09244B">
                                                        </path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </a>
                                </li>
                                {/* } */}
                                {/* { data['line'] && data['line'] !== "" && <li>
                                    <a href={data['line']} target="_blank" title='Line'>
                                        <svg fill="#000000" height="800px" width="800px" version="1.1" viewBox="0 0 296.528 296.528">
                                            <g>
                                                <path d="M295.838,115.347l0.003-0.001l-0.092-0.76c-0.001-0.013-0.002-0.023-0.004-0.036c-0.001-0.011-0.002-0.021-0.004-0.032
                                                    l-0.344-2.858c-0.069-0.574-0.148-1.228-0.238-1.974l-0.072-0.594l-0.147,0.018c-3.617-20.571-13.553-40.093-28.942-56.762
                                                    c-15.317-16.589-35.217-29.687-57.548-37.878c-19.133-7.018-39.434-10.577-60.337-10.577c-28.22,0-55.627,6.637-79.257,19.193
                                                    C23.289,47.297-3.585,91.799,0.387,136.461c2.056,23.111,11.11,45.11,26.184,63.621c14.188,17.423,33.381,31.483,55.503,40.66
                                                    c13.602,5.642,27.051,8.301,41.291,11.116l1.667,0.33c3.921,0.776,4.975,1.842,5.247,2.264c0.503,0.784,0.24,2.329,0.038,3.18
                                                    c-0.186,0.785-0.378,1.568-0.57,2.352c-1.529,6.235-3.11,12.683-1.868,19.792c1.428,8.172,6.531,12.859,14.001,12.86
                                                    c0.001,0,0.001,0,0.002,0c8.035,0,17.18-5.39,23.231-8.956l0.808-0.475c14.436-8.478,28.036-18.041,38.271-25.425
                                                    c22.397-16.159,47.783-34.475,66.815-58.17C290.172,175.745,299.2,145.078,295.838,115.347z M92.343,160.561H66.761
                                                    c-3.866,0-7-3.134-7-7V99.865c0-3.866,3.134-7,7-7c3.866,0,7,3.134,7,7v46.696h18.581c3.866,0,7,3.134,7,7
                                                    C99.343,157.427,96.209,160.561,92.343,160.561z M119.03,153.371c0,3.866-3.134,7-7,7c-3.866,0-7-3.134-7-7V99.675
                                                    c0-3.866,3.134-7,7-7c3.866,0,7,3.134,7,7V153.371z M182.304,153.371c0,3.033-1.953,5.721-4.838,6.658
                                                    c-0.712,0.231-1.441,0.343-2.161,0.343c-2.199,0-4.323-1.039-5.666-2.888l-25.207-34.717v30.605c0,3.866-3.134,7-7,7
                                                    c-3.866,0-7-3.134-7-7v-52.16c0-3.033,1.953-5.721,4.838-6.658c2.886-0.936,6.045,0.09,7.827,2.545l25.207,34.717V99.675
                                                    c0-3.866,3.134-7,7-7c3.866,0,7,3.134,7,7V153.371z M233.311,159.269h-34.645c-3.866,0-7-3.134-7-7v-26.847V98.573
                                                    c0-3.866,3.134-7,7-7h33.57c3.866,0,7,3.134,7,7s-3.134,7-7,7h-26.57v12.849h21.562c3.866,0,7,3.134,7,7c0,3.866-3.134,7-7,7
                                                    h-21.562v12.847h27.645c3.866,0,7,3.134,7,7S237.177,159.269,233.311,159.269z"/>
                                            </g>
                                        </svg>
                                    </a>
                                </li> } */}
                            </ul>
                            {/* {data['name'] && <h3>{data['name']}</h3> } */}
                            <h3>Rank: <b>{names[levelBought - 1]}</b></h3>
                            <CopyToClipboard text={userAddress}>
                                <p>{userAddress.substr(0, 4)}...{userAddress.substr(-4)}
                                    <svg onClick={() => { copySuccessfully(); }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon" role="img"><polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" className="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" className="ci-primary"></path></svg>
                                </p>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mt-4 mb-5 order-2 order-md-0'>
                        <div className='mainnetInr'>
                            <div className="memberBox">
                                <div className="innermbrbx">
                                    <h3>Not a member yet? Sign up with this upline</h3>
                                    <strong>Personal link :</strong>
                                    <CopyToClipboard text={`${BASE_URL}register?referral=` + (userId)}>
                                        <button className='referralBtn addressCopyBtn' onClick={() => { copySuccessfully(); }}>{BASE_URL}register?referral={(userId)}<img src={copyIcon} alt="Copy Icon" /></button>
                                    </CopyToClipboard>

                                    {/* <CopyToClipboard text={`${BASE_URL}register?referral=` + (data['referral_link'] && data['referral_link'] != '' ? data['referral_link'] : userId)}>
                                        <button className='referralBtn addressCopyBtn' onClick={() => { copySuccessfully(); }}>{BASE_URL}register?referral={(data['referral_link'] && data['referral_link'] != '' ? data['referral_link'] : userId)}<img src={copyIcon} alt="Copy Icon" /></button>
                                    </CopyToClipboard> */}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className='autoLogin'>
                                        <a href={`${BASE_URL}register?referral=` + (userId)} target='_blank'>
                                            <button className='am_btn'>Sign Up</button>
                                        </a>
                                    </div>
                                    {!isDesktop && <div className='autoLogin'>
                                        <a href={"https://dapp.oxchange.finance/#swap"} target='_blank'>
                                            <button className='am_btn'>Trade OC</button>
                                        </a>
                                    </div>}
                                    {!isDesktop && <div className='autoLogin'>
                                        <a href={"https://gaming.oxchange.finance/"} target='_blank'>
                                            <button className='am_btn'>Play with OC</button>
                                        </a>
                                    </div>}
                                </div>
                            </div>
                            <div className='row totalIncomeBoxs'>
                                <div className='col-12 col-md-3 mb-2'>
                                    <div className='inrBox'>
                                        <p>Total Direct Income</p>
                                        <h3>{directIncome}</h3>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3 mb-2'>
                                    <div className='inrBox'>
                                        <p>Total Level Income</p>
                                        <h3>{levelIncome}</h3>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3 mb-2'>
                                    <div className='inrBox'>
                                        <p>My Team Total</p>
                                        <h3>{myTeamTotal}</h3>
                                    </div>
                                </div>
                                <div className='col-12 col-md-3 mb-2'>
                                    <div className='inrBox'>
                                        <p>OxCash Bonanza</p>
                                        <h3>{parseFloat(oxCashBonanza).toFixed(2)} OC</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="treeAeromatix">
                <div className='titleBox'>
                    <h2>Oxcash NFTPro 3.0</h2>
                    <p>Oxcash NFTPro 3.0 ({levelBought >= 15 ? 15 : levelBought} out of 15 levels)</p>
                </div>
                <div className="row rowtreebox">
                    {idsArray.map((ids, index) => {
                        const isActive = (index + 1) <= levelBought;
                        return <Slot ocPrice={ocPrice} levelPrices={levelPrices} key={index} reinvestCounts={reinvestCounts} userId={userId} ids={ids} index={index} isActive={isActive} onSlotClick={handleSlotClick} onBuyLevel={handleBuyLevel}></Slot>
                    })}
                </div>
            </section>
            <section className='accountPreviewMain mt-4'>
                {/* dashboardPreviewMain */}
                {/* <div className='titleBox'>
                        <h2>Oxcash NFTPro 3.0 & Activities</h2>
                    </div> */}
                <div className='row tablemainnet align-items-start'>
                    <div className='col-12 col-lg-7'>
                        <div className="titleBox">
                            <h2>Recent Activities</h2>
                        </div>
                        <div className='tableInr' id='activitiesView'>
                            <div className='table-responsive dashboardPg'>
                                <table className="table table-striped-columns">
                                    {/* <thead>
                                            <tr>
                                                <th width="10%"></th>
                                                <th width="20%"></th>
                                                <th width="35%"></th>
                                                <th width="35%"></th>
                                            </tr>
                                        </thead> */}
                                    <tbody>
                                        {activity.map((a, index) => {
                                            return (a.eventsName == "Registration" ? <tr key={index}>
                                                <td><img src={logofav} alt="ETH" width="20" height="20" /></td>
                                                <td><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                <td className='txtinf'>NEW USER <p>JOINED</p></td>
                                                <td className='linkshr text-end'>
                                                    <a href={BASE_BSC_SCAN_URLS + "/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                    <span>{a.date}</span></td>
                                            </tr> : <tr key={index}>
                                                <td><img src={logofav} alt="ETH" width="20" height="20" /></td>
                                                <td><a href="#!">ID {parseInt(a.userId)}</a></td>
                                                <td className='txtinf'><span>+</span>{ethers.utils.formatEther(a.amount)} USD<span>in</span><p>OC</p></td>
                                                <td className='linkshr text-end'>
                                                    <a href={BASE_BSC_SCAN_URLS + "/tx/" + a.txId} target="blank"><svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg></a>
                                                    <span>{a.date}</span></td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-5'>
                        <div className="titleBox">
                            <h2>Oxcash NFTPro 3.0 Contracts</h2>
                        </div>
                        <div className='mainnetInr mb-4'>
                            <div className='row linkBox'>
                                <strong>Oxcash NFTPro 3.0</strong><span>{OxNFTContract.substr(0, 4)}...{OxNFTContract.substr(-4)}
                                    <a href={"https://polygonscan.com/address/" + OxNFTContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                </span>
                            </div>
                            <div className='row linkBox'>
                                <strong>OC</strong><span>{usdtContract.substr(0, 4)}...{usdtContract.substr(-4)}
                                    <a href={"https://polygonscan.com/token/" + usdtContract} target="_blank"><svg className="h-18px w-18px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.314 3.813a3.446 3.446 0 1 1 4.873 4.874l-3.331 3.331a3.418 3.418 0 0 1-4.88-.001.5.5 0 0 1 .715-.7 2.417 2.417 0 0 0 3.452 0l.004-.004L15.48 7.98a2.446 2.446 0 1 0-3.46-3.46l-.416.417a.5.5 0 1 1-.707-.707l.417-.417Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.26 7.225a3.417 3.417 0 0 1 3.765.758.5.5 0 0 1-.715.7 2.417 2.417 0 0 0-3.452 0l-.003.004L4.52 12.02a2.446 2.446 0 0 0 3.46 3.46l.416-.417a.5.5 0 1 1 .708.707l-.417.417a3.446 3.446 0 1 1-4.874-4.874l3.332-3.332a3.417 3.417 0 0 1 1.115-.756Z" fill="#fff"></path></svg></a>
                                </span>
                            </div>
                            <div className='row linkBox'>
                                <strong>Members total</strong><span>
                                    {totalMembers}
                                </span>
                            </div>
                            <div className='row linkBoxBtm'>
                                <p>Turnover, USD</p><span>{allInvested}</span>
                            </div>
                        </div>
                        {/* <div className='row dashpgMbr'>
                            <div className='col-12 col-md-6'>
                                <div className='mainnetInr'>
                                    <h3>Members total</h3>
                                    <strong>{totalMembers}</strong>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='mainnetInr'>
                                    <h3>Members received, USD</h3>
                                    <strong>{allInvested}</strong>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard