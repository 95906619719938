import React from 'react'
import './Banner.css'
import slider1 from '../../Assets/images/banner-slider/slider1.png'
import slider2 from '../../Assets/images/banner-slider/slider2.png'
import slider3 from '../../Assets/images/banner-slider/slider3.png'
import slider4 from '../../Assets/images/banner-slider/slider4.png'
import Carousel from 'nuka-carousel';
import { Link } from 'react-router-dom';

const Banner = () => {

    return (
        <>
            <section className="ox_banner">
                <div className="slider-container">
                    <Carousel wrapAround={true} slidesToShow={1} autoplay={true}>
                        <img src={slider1} alt="slider1" width="1920" height="450" />
                        <img src={slider2} alt="slider2" width="1920" height="450" />
                        <img src={slider3} alt="slider3" width="1920" height="450" />
                        <img src={slider4} alt="slider4" width="1920" height="450" />
                    </Carousel>
                    <Link to={"https://bbevents.live/"} className='buyogaBtn' target='_blank'>Buy OGA Tickets</Link>
                </div>
            </section>
        </>
    )
}

export default Banner