import { PriceOracle } from "../config";
import { useContract } from "./useContract";

import PriceABI from "../abi/priceOracle.json";
import { useEffect, useMemo, useState } from "react";
import { ethers } from "ethers";
import { formatEther } from "ethers/lib/utils";
import useActiveWeb3React from "./useWeb3";

export const useOcPrice = () => {
    const { account } = useActiveWeb3React()
    const [price, setPrice] = useState(0);
    const ocContract = useContract( PriceOracle, PriceABI )

    useEffect(() => {
        const inter = setInterval(async () => {
            // if ( !account ) return
            // console.log("oc contract: ", account)
            const response = await fetch('https://oc-price-api.vercel.app/oc-price');
            const data = await response.json();
            const ocPrice = data['price']
            console.debug("oc price", ocPrice)
            setPrice( ocPrice )
        }, 4500)
        return () => clearInterval(inter)
    }, [ocContract, setPrice, account])

    useEffect(() => {
        (
            async () => {
                // if ( !account ) return
                console.log("oc contract: ", account)
                const ocPrice = await ocContract.fetchTokenPrice( )
                console.debug("oc price", formatEther(ocPrice))
                setPrice( formatEther(ocPrice) )
            }
        )()
    }, [ocContract, setPrice, account])

    return useMemo(() => price, [price]);
}